<template>
  <div class="template-1">
    <page-header class="page-header container">
      <h1 @change="page_load()">
        Gideon Membership Application Preview
      </h1>
    </page-header>
    <page-body class="mx-lg">
      <section class="section-1">

        <div>
          <div class="card">
            <!-- <div class="card-header">
        <div class="row">
          <div class="col-5 col-lg-8">
            <span class="h6">Gideon Membership Application Preview</span>
          </div>
          <div class="col-7 col-lg-4 text-right">
            <img alt="Image placeholder" src="../assets/mastercard.png" width="40" class="mr-2">
                    <img alt="Image placeholder" src="../assets/mastercard.png" width="40" class="mr-2">
                    <img alt="Image placeholder" src="../assets/mastercard.png" width="40">
          </div>
        </div>
      </div> -->
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <span class="h6">Section I. PERSONAL INFORMATION</span>
                </div>
              </div>
            </div>
            <div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Full Name</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.cad_prefix }}
                        {{ candidateDetails.candidate.cad_first_name }}
                        {{ candidateDetails.candidate.cad_prefix }}
                        {{ candidateDetails.candidate.cad_middle_name }}
                        {{ candidateDetails.candidate.cad_last_name }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Mailing Address</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.adr_address_1 }}
                      </p>
                      <div>
                        {{ candidateDetails.candidate.adr_city }},
                        {{ candidateDetails.candidate.adr_state }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Phone Number</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateDetails.candidate.phn_number }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Alternate Phone Number</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0"></p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Date of Birth</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.cad_date_of_birth }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Married ?</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.cad_marital_status }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Spouse Name</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.cax_wife_name }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Section 2 -->
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <span class="h6">Section II. SPIRITUAL INFORMATION</span>
                </div>
              </div>
            </div>
            <div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="h6 text-sm mb-1 mb-sm-0">
                        <span class="avatar bg-light text-white rounded-circle avatar-sm">A</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in spiritualQuestions.filter(
                  (quest) => quest.que_sort == 1
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="h6 text-sm mb-1 mb-sm-0">
                        <span class="avatar bg-light text-white rounded-circle avatar-sm">B</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in spiritualQuestions.filter(
                  (quest) => quest.que_sort == 2
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="h6 text-sm mb-1 mb-sm-0">
                        <span class="avatar bg-light text-white rounded-circle avatar-sm">C</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in spiritualQuestions.filter(
                  (quest) => quest.que_sort == 3
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>

                <li class="list-group-item" >
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="h6 text-sm mb-1 mb-sm-0">
                        <span class="avatar bg-light text-white rounded-circle avatar-sm">D</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Church Name</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateDetails.church.org_name }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Denomination</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.church.org_denomination }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Church Address</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.church.adr_zipcode }}
                        {{ candidateDetails.church.adr_adress_1 }}
                      </p>
                      <div>
                        {{ candidateDetails.church.adr_city }},
                        {{ candidateDetails.church.adr_state }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Church Email</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateDetails.church.eml_address }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Web Address</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.church.org_web_address }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Church Phone No.</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateDetails.church.phn_number }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="candidateDetails.minister != null">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="h6 text-sm mb-1 mb-sm-0">
                        <span class="avatar bg-light text-white rounded-circle avatar-sm">E</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="candidateDetails.minister != null" >
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Pastor's/Minister Name</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateDetails.minister.min_name }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="candidateDetails.minister != null">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Pastor's/Minister Phone No.
                      </small>
                    </div>
                    <div class="col-sm-7" v-if="candidateDetails.minister != null">
                      <p class="mb-0">{{ candidateDetails.minister.phn_number }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="candidateDetails.minister != null">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Pastor's/Minister Email</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateDetails.minister.eml_address }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="h6 text-sm mb-1 mb-sm-0">
                        <span class="avatar bg-light text-white rounded-circle avatar-sm">F</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Have you held previous Gideon membership?</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.cax_previous_gideonmebership }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Where?</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateDetails.candidate.cax_previous_gideon_location }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Approximate date and your Gideon number:</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{
                            candidateDetails.candidate
                              .cax_previous_gideon_membershipDate
                        }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!--  Section 3-->
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <span class="h6">Section III. BUSINESS OCCUPATIONS</span>
                </div>
              </div>
            </div>
            <div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Business Name</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">{{ candidateBusiness.org_name }}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-5">
                      <small class="h6 text-sm mb-3 mb-sm-0">Business Address</small>
                    </div>
                    <div class="col-sm-7">
                      <p class="mb-0">
                        {{ candidateBusiness.adr_zipcode }}
                        {{ candidateBusiness.adr_address_1 }}
                      </p>
                      <div>
                        {{ candidateBusiness.adr_city }},
                        {{ candidateBusiness.adr_state }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in businessQuestions.filter(
                  (quest) => quest.que_sort == 1
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in businessQuestions.filter(
                  (quest) => quest.que_sort == 3
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-12">
                      <small class="h6 text-sm mb-3 mb-sm-0">In the exercise or conduct of your business, would
                        you:</small>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in businessQuestions.filter(
                  (quest) => quest.que_sort == 2
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!--  Section 4-->
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <span class="h6">Section IV. PROFESSIONAL OR INSTITUTIONAL OCCUPATIONS</span>
                </div>
              </div>
            </div>
            <div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="question in professionQuestions.filter(
                  (quest) => quest.que_sort == 1
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in professionQuestions.filter(
                  (quest) => quest.que_sort == 2
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in professionQuestions.filter(
                  (quest) => quest.que_sort == 3
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in professionQuestions.filter(
                  (quest) => quest.que_sort == 4
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-for="question in professionQuestions.filter(
                  (quest) => quest.que_sort == 5
                )" :key="question.key">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">{{
                          question.que_text
                      }}</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0" v-if="question.que_type == 'yes_no'">
                        {{ question.caq_answer_status == true ? "Yes" : "No" }}
                      </p>
                      <p class="mb-0" v-if="question.que_type == 'text'">
                        {{ question.caq_answer_text }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-9">
                      <small class="h6 text-sm mb-3 mb-sm-0">Institution</small>
                    </div>
                    <div class="col-sm-3">
                      <p class="mb-0">
                        {{ candidateAcademic.org_name }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </page-body>
    <page-footer></page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import DataTable from '@/components/DataTableDigitalMembers.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'validate-membership-applications-cm',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      title: 'Gideon Membership Application Preview',
      onlyUSA: true,
      selectedCandidate: {},
      selectedAction: '',
      camp_state_source: '',
      list_type: 'validate_membership_applications',
      fields: [
        { key: 'cad_name', label: 'Name', sortable: true },
        { key: 'cad_add_date', label: 'Date', sortable: true },
        { key: 'eml_address', label: 'Email', sortable: true },
        { key: 'sponsor', label: 'Sponsor', sortable: true },
        { key: 'yes', label: 'Yes', sortable: false },
        { key: 'no', label: 'No', sortable: false },
        { key: 'actions', label: 'View', sortable: false },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
      },
    }
  },
  components: {
    campSelector: CampSelect,
    pageHeader: pageHeader,
    pageBody: pageBody,
    dataTable: DataTable,
    pageFooter: pageFooter,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      getMemberChurches: 'campModule/getMemberChurches',
      getCandidate: 'digitalCandidateMember/getCandidate',
      getCampMembersChurchesUrl: 'churchMinistry/getCampMembersChurchesUrl',
      loadChurches: 'churchMinistry/loadChurches',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    createTitle(code) {
      return {
        st: this.translations.tcStateValidateMembershipApplications,
        cm: this.translations.tcCampValidateMembershipApplications,
      }[code]
    },
    selectedPresidentLabel() {
      return `${this.selectedCandidate.cmi_president_firstname} ${this.selectedCandidate.cmi_president_lastname} - Camp President`
    },
    async campStateSource() {
      await (async () => {
        this.camp_state_source = `${process.env.VUE_APP_MEMBERSHIP_VALIDATION}/candidate/validation?sValue=${this.userCampKey}|${this.userId}`
      })()
    },

    loadTranslations() {
      this.addItems.text = this.translations.tcAddVisit
      const { tcActions, tcChurchName, tcName } = this.translations.components['data-table-member-churches']
      this.fields[0].label = tcName
      this.fields[1].label = tcChurchName
      this.fields[2].label = tcActions
    },
    async page_load() {
      // this.setLoadingStatus(true)
      // await this.campStateSource()
      // this.load_finished()
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getCandidate(this.membershipCandidateKey),
          this.getViewTranslations(),
          this.getComponentTranslations('camp-select', 'data-table-member-churches', 'security-banner'),
        ]).then((results) => {
          const componentTranslations = results[3]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    load_finished() {
      this.setLoadingStatus(false)
    },
    loadTranslations() {
      const fourth = this.$route.path.split('/')[4]
      this.title = this.createTitle(!!fourth && fourth !== 'menu' ? fourth : 'cm')
    },
    async get_translations() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select'),
      ]).then((results) => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.load_finished()
      })
    },
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      candidateDetails: 'digitalCandidateMember/candidate',
      membershipCandidateKey: 'digitalCandidateMember/membershipCandidateKey',
      userStateKey: 'user/userStateKey',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      prefCulture: 'user/userPreferredCulture',
      memberChurches: 'campModule/campMemberChurches',
      prefCulture: 'user/userPreferredCulture',
      userOrgKey: 'user/userCampKey',
    }),
    spiritualQuestions() {
      return this.candidateDetails.questions.filter(
        (question) => question.que_category.trim() == "spiritual"
      );
    },
    businessQuestions() {
      return this.candidateDetails.questions.filter(
        (question) => question.que_category.trim() == "business"
      );
    },
    candidateBusiness() {
      if (this.candidateDetails.business == null) {
        return {}
      }
      return this.candidateDetails.business
    },
    candidateAcademic() {
      if (this.candidateDetails.academic == null) {
        return {}
      }
      return this.candidateDetails.academic
    },
    professionQuestions() {
      return this.candidateDetails.questions.filter(
        (question) => question.que_category.trim() == "profession"
      );
    },
  },
  beforeMounted() {
    //  this.page_load()
  },
  beforeUpdate() {
    //  this.page_load()
  },
  async created() {
    this.get_translations()
    this.page_load()
  },
}
</script>

<style lang="scss">
.avatar {
  position: relative;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  height: 3.125rem;
  width: 3.125rem;
}
</style>
